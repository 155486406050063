import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';


const Contact = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
      const [success, setSuccess] = useState();
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          const response = await emailjs.send(
            'service_kx5f8dp',
            'template_aev219q',
            formData,
            'V90X-Y_HJVkcbfy6I'
          );
      
          console.log('Email sent successfully!', response);
          console.log(response.status);
          if(response.status === 200) {
            setSuccess('Thanks for contacting us we will get back to you soon.');
            setTimeout(() => {
                setSuccess('');
            }, 10000);
          }
          
          // Clear form data after sending the email
          setFormData({
            name: '',
            email: '',
            message: '',
          });
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
    return (
        <div>
          
            <div className="container contactUsHeightForFotter">
            <a href='https://vayunewtab.com/'><img src='search-logo.png' className="App-logo" alt="logo" />
        <h3 className='logotext'>
        Vayunewtab
        </h3></a>
        <div className="row positionRelative" style={{margin: '30px 0px'}}>
          <div className="col-md-8 boxshadowBorder"> 
            <div className='contact-us'>
            <h3>Contact us</h3>
            <lead class="center contactSubHead">If you have any query, please fill the contact form below and we will
                        get back to you soon</lead>
            <form onSubmit={handleSubmit}>
        <div className='form_parent'>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder='Name' required/>
        </div>
        <div className='form_parent'>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email' required/>
        </div>
        <div className='form_parent'>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} placeholder='Message' required/>
        </div>
        <div className='d-flex text-center justify-content-center'><button type="submit" className='btnStyle'>Submit</button></div>
        {
            success ? <h3 style={{textAlign: 'center', fontSize: '1.2rem'}}>{success}</h3> : ''
        }
        
      </form>
            </div>
          </div>
          <div className='col-md-4'>
          <div class="mapSection">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.7818220878053!2d145.16316397676724!3d-37.88878473833082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad63fc49b201ecb%3A0xc3038a74e63eb200!2s697%20Waverley%20Rd%2C%20Glen%20Waverley%20VIC%203150%2C%20Australia!5e0!3m2!1sen!2sin!4v1691175199041!5m2!1sen!2sin" width="100%" height="300" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div class="borderBttn">
                    <h3>Registered office:</h3>
                </div>
                <div class="address">
                    <b><div>Vayunewtab.com</div> (A Subsidiary of Dijura Pty Ltd)</b> <div>697 Waverley Road, Glen Waverley, VIC 3150, Australia</div>
                </div>
              </div>
          </div>
        </div>
    </div>
        </div>
    );
}

export default Contact;