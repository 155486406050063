import React from 'react';
import './App.css';
import Footer from './Footer';
import Home from './Home';
import Search from './Search';
import Tos from './Tos';
import Privacy from './Privacy';
import Contact from './Contact-us';
 
// import { Routes, Route, Link, useNavigate } from "react-router-dom";

import { Routes, Route } from "react-router-dom";

const App = () => { 
  // const navigate = useNavigate();

  // const navigate = useNavigate();

  return (
    <div className="App">

     
  


      <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/search"  element={<Search />} />
      <Route path="/terms"  element={<Tos />} />
      <Route path="/privacy"  element={<Privacy />} />
      <Route path="/contact"  element={<Contact />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
