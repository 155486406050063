import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore"


const firebaseConfig = {
    apiKey: "AIzaSyDSQMrGf2uIIrfxTV2goCi1fhGFOd5h72U",
    authDomain: "searchengines-9499b.firebaseapp.com",
    projectId: "searchengines-9499b",
    storageBucket: "searchengines-9499b.appspot.com",
    messagingSenderId: "924036855859",
    appId: "1:924036855859:web:112bd5ebb3077fa1de4b46",
    measurementId: "G-88VJ2TTT19"
  };

  // Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore()