import React from 'react';
 
import Searchbox from './Searchbox';


const Search = (props) => {
  
    return (
        <div className='row minHeightRow'>
        <div className='col-md-3 col-xs-1'>

        </div>
        <div className='col-md-6 col-xs-10'>
        <header className="App-header">
        <img src='search-logo.png' className="App-logo" alt="logo" />
        <h3 className='logotext'>
        searcholl
        </h3>
        <div className='searchInputDiv'>
        <span class="material-symbols-outlined searchIcon">search</span>
     
        <Searchbox />
        </div>
        <p className='saferText'>Search to make the world Safer</p>
       
      </header>
        </div>
        <div className='col-md-3 col-xs-1'>

        </div>
      
      </div>
    );
}

export default Search;